import React, { FC } from 'react';
import Menu from './Menu';

const Layout: FC = ({ children }) => (
  <div className="grid grid-cols-main">
    <div>
      <Menu />
    </div>
    <div className="pb-56">{children}</div>
  </div>
);

export default Layout;
