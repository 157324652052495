import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Layout from './components/common/Layout';
import PrivateRoute from './components/common/PrivateRoute';
import AddServiceStationPage from './pages/AddServiceStation';
import Login from './pages/Login';
import Options from './pages/Options';
import ReportPage from './pages/Report';
import Reports from './pages/Reports';
import ServiceStationPage from './pages/ServiceStation';
import Signup from './pages/Signup';
import UsedCarPage from './pages/UsedCar';

function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <PrivateRoute exact path="/reports">
            <Reports />
          </PrivateRoute>
          <PrivateRoute exact path="/reports/:id">
            <ReportPage />
          </PrivateRoute>
          <PrivateRoute exact path="/options">
            <Options />
          </PrivateRoute>
          <PrivateRoute exact path="/service-stations/add">
            <AddServiceStationPage />
          </PrivateRoute>
          <PrivateRoute exact path="/service-stations/:id">
            <ServiceStationPage />
          </PrivateRoute>
          <PrivateRoute exact path="/used-cars/:id">
            <UsedCarPage />
          </PrivateRoute>
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
