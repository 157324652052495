export enum ReportSubject {
  usedCars = 'used-cars',
  showrooms = 'showrooms',
  serviceStations = 'service-stations',
  claim = 'claim',
  adv = 'adv',
  other = 'other',
  review = 'review',
}

export enum ReportStatus {
  open = 'open',
  closed = 'closed',
  processed = 'processed',
  rejected = 'rejected',
}

interface Message {
  date: string;
  text: string;
  from: 'user' | 'service';
}

export interface Report {
  id: string;
  subject: ReportSubject;
  ref?: string;
  name?: string;
  email?: string;
  text?: string;
  messages?: Message[];
  status: ReportStatus;
  payload?: Record<string, any>;
  createdAt: string;
}
