import axios from 'axios';
import { PopulatedUsedCar, UsedCar } from '../types/used-car.type';
import { getTokens, setTokens } from './auth';
import { normalizeCarData, normalizeServiceStationData } from './normalizer';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${getTokens().access_token}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (
      error.response?.status === 401 &&
      originalRequest.url === '/auth/signin'
    ) {
      return Promise.reject(error);
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios
        .post(
          `${process.env.REACT_APP_API_URI}/auth/refresh`,
          {},
          { withCredentials: true },
        )
        .then((res) => {
          if (res.status === 200) {
            setTokens(res.data);
            return api(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  },
);

export const fetcher = (url: string) => api.get(url).then(({ data }) => data);

export const postFetcher = (
  url: string,
  params: Record<string, any> = {},
  skip: number = 0,
) => api.post(url, { ...params, skip }).then(({ data }) => data);

export const serviceStationFetcher = (url: string) =>
  fetcher(url).then(normalizeServiceStationData);

export const usedCarFetcher = (url: string) =>
  fetcher(url).then((data) =>
    normalizeCarData<UsedCar, PopulatedUsedCar>(data),
  );
