import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useSWRInfinite } from 'swr';
import { fetcher } from '../libs/api';

const LIMIT = 40;

const Reports: FC = () => {
  const { data, size, setSize } = useSWRInfinite(
    (index) =>
      `/reports?limit=${LIMIT}&skip=${
        index * LIMIT
      }&status=open&status=processed`,
    fetcher,
  );

  return (
    <div className="p-2">
      <table className="table-auto">
        <thead>
          <tr>
            <th>Status</th>
            <th>Type</th>
            <th>Date</th>
            <th>Text</th>
          </tr>
        </thead>
        <tbody>
          {data?.flat().map((item) => (
            <tr key={item.id}>
              <td className="border p-2">
                <Link
                  className="text-blue-500 underline"
                  to={`/reports/${item.id}`}
                >
                  {item.status}
                </Link>
              </td>
              <td className="border p-2">{item.subject}</td>
              <td className="border p-2">
                {new Date(item.createdAt).toLocaleString()}
              </td>
              <td className="border p-2 truncate">
                {item.text ||
                  JSON.stringify(
                    Object.fromEntries(
                      Object.entries(item.payload).filter(([_, val]) => !!val),
                    ),
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button
        className="mt-4 h-8 rounded-sm bg-blue-500 shadow text-white px-4"
        onClick={() => setSize(size + 1)}
      >
        Show More
      </button>
    </div>
  );
};

export default Reports;
