import React, { FC } from 'react';
import { Link, NavLink, NavLinkProps } from 'react-router-dom';

const MenuLink: FC<NavLinkProps> = ({ children, ...props }) => (
  <li>
    <NavLink
      className="px-2 flex items-center h-10 text-white hover:bg-gray-700"
      activeClassName="bg-teal-500 hover:bg-teal-500"
      {...props}
    >
      {children}
    </NavLink>
  </li>
);

const Menu: FC = () => (
  <nav className="bg-gray-800 min-h-screen flex flex-col">
    <ul className="flex-grow">
      <MenuLink exact to="/">
        Home
      </MenuLink>
      <MenuLink to="/reports">Reports</MenuLink>
      <MenuLink to="/options">Options</MenuLink>
      <MenuLink to="/service-stations/add">Add Service Stations</MenuLink>
      <MenuLink to="/showrooms">Showrooms</MenuLink>
      <MenuLink to="/used-cars">Used Cars</MenuLink>
    </ul>
    <Link
      to="/logout"
      className="p-2 text-white text-opacity-50 hover:text-opacity-100 text-sm"
    >
      logout
    </Link>
  </nav>
);

export default Menu;
