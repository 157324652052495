import React, { FC, FormEvent, useCallback, useState } from 'react';
import Modal from 'react-modal';
import { Option, OptionGroups } from '../../types/option.type';

interface Props {
  option: Option;
  onUpdate: (option: Option) => void;
}

const EditOptionModal: FC<Props & Modal.Props> = ({
  onUpdate,
  option,
  ...props
}) => {
  const [texts, changeTexts] = useState(option.texts);
  const [weight, changeWeight] = useState(option.metadata?.weight);
  const [groups, changeGroups] = useState(option.groups || []);

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onUpdate({
        ...option,
        texts,
        groups,
        metadata: { ...option.metadata, weight },
      });
    },
    [onUpdate, option, texts, weight, groups],
  );

  return (
    <Modal {...props}>
      <form className="w-full max-w-sm" onSubmit={handleSubmit} action="">
        <div className="mb-2">
          <label>
            Name: <br />
            <input
              value={texts.name}
              onChange={(event) =>
                changeTexts({ ...texts, name: event.target.value.trim() })
              }
              type="text"
              className="border shadow h-10 px-2 rounded"
            />
          </label>
        </div>
        <div className="mb-2">
          <label>
            nameRu: <br />
            <input
              value={texts.nameRu}
              onChange={(event) =>
                changeTexts({ ...texts, nameRu: event.target.value.trim() })
              }
              type="text"
              className="border shadow h-10 px-2 rounded"
            />
          </label>
        </div>
        <div className="mb-2">
          <label>
            nameUk: <br />
            <input
              value={texts.nameUk}
              onChange={(event) =>
                changeTexts({ ...texts, nameUk: event.target.value.trim() })
              }
              type="text"
              className="border shadow h-10 px-2 rounded"
            />
          </label>
        </div>
        <div className="mb-2">
          <label>
            nameDec: <br />
            <input
              value={texts.nameDec}
              onChange={(event) =>
                changeTexts({ ...texts, nameDec: event.target.value.trim() })
              }
              type="text"
              className="border shadow h-10 px-2 rounded"
            />
          </label>
        </div>
        <div className="mb-2">
          <label>
            nameDecUk: <br />
            <input
              value={texts.nameDecUk}
              onChange={(event) =>
                changeTexts({ ...texts, nameDecUk: event.target.value.trim() })
              }
              type="text"
              className="border shadow h-10 px-2 rounded"
            />
          </label>
        </div>
        <div className="mb-2">
          <label>
            Weight: <br />
            <input
              value={weight}
              onChange={(event) =>
                changeWeight(Number(event.target.value) || undefined)
              }
              type="number"
              className="border shadow h-10 px-2 rounded"
            />
          </label>
        </div>
        <div className="mb-2">
          <label>
            Groups: <br />
            <select
              multiple
              value={groups}
              className="w-full h-24 border shadow h-10 px-2 rounded"
              onChange={(event) =>
                changeGroups(
                  Array.from(
                    event.target.selectedOptions,
                    (item) => item.value,
                  ) as OptionGroups[],
                )
              }
            >
              <option value="car-safety">car safety</option>
              <option value="car-comfort">car comfort</option>
              <option value="car-salon">car salon</option>
              <option value="car-multimedia">car multimedia</option>
              <option value="car-other">car other</option>
              <option value="car-special">car special</option>
            </select>
          </label>
        </div>
        <button className="h-10 border rounded bg-blue-500 text-white px-8">
          Save
        </button>
      </form>
    </Modal>
  );
};

export default EditOptionModal;
