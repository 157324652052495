import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import EditForm from '../components/service-stations/EditForm';
import { api, serviceStationFetcher } from '../libs/api';
import { PopulatedServiceStation } from '../types/service-station.type';

const ServiceStationPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [status, changeStatus] = useState<'stale' | 'loading' | 'saved'>(
    'stale',
  );
  const { data: serviceStation } = useSWR<PopulatedServiceStation>(
    `/service-stations/${id}`,
    serviceStationFetcher,
  );

  function handleSubmit(data: unknown): void {
    if (!serviceStation || status !== 'stale') return;
    changeStatus('loading');

    api
      .patch(`/service-stations/${serviceStation.id}`, data)
      .then(() => changeStatus('saved'))
      .catch((err) => {
        changeStatus('stale');
        alert('error');
        console.log(err);
      });
  }

  if (status === 'saved') {
    return <div className="p-2 text-2xl">saved successfully!</div>;
  }

  return (
    <div className="p-2">
      <EditForm
        onSubmit={handleSubmit}
        serviceStation={serviceStation}
        disabled={status === 'loading'}
      />
    </div>
  );
};

export default ServiceStationPage;
