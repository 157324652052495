import React, { FC, useMemo, useState } from 'react';
import useSWR from 'swr';
import { postFetcher } from '../../../libs/api';
import { Option } from '../../../types/option.type';
import SelectOptionsModal from './SelectOptionsModal';

interface SingleProps {
  type: string;
  placeholder: string;
  className?: string;
  value?: string;
  onChange: (value: string) => void;
  multiple: false;
}

interface MultipleProps {
  type: string;
  placeholder: string;
  className?: string;
  value?: string[];
  onChange: (value: string[]) => void;
  multiple: true;
}

type Props = SingleProps | MultipleProps;

const OptionsSelector: FC<Props> = ({
  type,
  placeholder,
  className,
  onChange,
  value,
  multiple,
}) => {
  const [isModalOpen, changeIsModalOpen] = useState(false);

  const params = useMemo(() => ({ type, limit: 0 }), [type]);
  const { data: options } = useSWR<Option[]>(
    ['/options/_search', params],
    postFetcher,
  );

  function handleFinish(value: any): void {
    onChange(value);
    changeIsModalOpen(false);
  }

  return (
    <>
      <SelectOptionsModal
        multiple={multiple}
        value={value}
        type={type}
        options={options}
        isOpen={isModalOpen}
        onRequestClose={() => changeIsModalOpen(false)}
        onFinish={handleFinish}
      />
      <button
        onClick={() => changeIsModalOpen(true)}
        type="button"
        className={`${
          className || ''
        } border rounded h-10 px-2 w-full text-left text-gray-500`}
      >
        {placeholder}
        <span className="text-black">
          {' '}
          {value &&
            (Array.isArray(value)
              ? `(${value?.length} selected)`
              : options?.find((item) => item.id === value)?.texts.name)}
        </span>
      </button>
    </>
  );
};

export default OptionsSelector;
