export enum OptionTypes {
  city = 'city',
  brand = 'brand',
  model = 'model',
  region = 'region',
  option = 'option',
  drive = 'drive',
  gearbox = 'gearbox',
  body = 'body',
  fuel = 'fuel',
  service = 'service',
}

export enum OptionGroups {
  carSafety = 'car-safety',
  carComfort = 'car-comfort',
  carSalon = 'car-salon',
  carMultimedia = 'car-multimedia',
  carOther = 'car-other',
  carSpecial = 'car-special',
}

export interface Option {
  id: string;
  slug: string;
  type: OptionTypes;
  metadata?: { [key: string]: any };
  groups?: OptionGroups[];
  disabled: boolean;
  parent?: string;
  texts: {
    name: string;
    nameUk?: string;
    nameRu?: string;
    nameDec?: string;
    nameDecUk?: string;
    alts?: string[];
  };
}
