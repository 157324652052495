import React, { FC, FormEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { signUp } from '../libs/auth';

const Signup: FC = () => {
  async function handleSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();
    changeStatus('loading');
    try {
      await signUp({ name, email, password });
      history.push('/login');
    } finally {
      changeStatus('error');
    }
  }

  const history = useHistory();
  const [status, changeStatus] = useState<'stale' | 'loading' | 'error'>(
    'stale',
  );
  const [name, changeName] = useState('');
  const [email, changeEmail] = useState('');
  const [password, changePassword] = useState('');

  return (
    <div>
      <form
        action=""
        method="post"
        className="p-2 max-w-sm"
        onSubmit={handleSubmit}
      >
        <div className="p-2">
          <input
            type="text"
            placeholder="name"
            className="border w-full rounded-sm shadow-sm h-8 px-2"
            value={name}
            onChange={(event) => changeName(event.target.value)}
          />
        </div>
        <div className="p-2">
          <input
            type="email"
            placeholder="email"
            className="border w-full rounded-sm shadow-sm h-8 px-2"
            value={email}
            onChange={(event) => changeEmail(event.target.value)}
          />
        </div>
        <div className="p-2">
          <input
            className="border w-full rounded-sm shadow-sm h-8 px-2"
            placeholder="password"
            type="password"
            value={password}
            onChange={(event) => changePassword(event.target.value)}
          />
        </div>
        <div className="p-2 flex items-center justify-between">
          <button
            disabled={status === 'loading'}
            type="submit"
            className={`rounded-sm ${
              status === 'error' ? 'bg-red-500' : 'bg-blue-500'
            } h-8 px-6 shadow text-white hover:bg-blue-600 disabled:bg-gray-500`}
          >
            Sign Up
          </button>

          <Link
            to="/login"
            className="block h-8 px-2 text-blue-500 hover:underline"
          >
            Login
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Signup;
