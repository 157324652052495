import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { api, fetcher } from '../libs/api';
import { Report, ReportStatus, ReportSubject } from '../types/report.type';

function payloadPropToString(val: any): string {
  if (Array.isArray(val)) {
    return typeof val[0] === 'object'
      ? val.map((item) => item.title || item.toString()).join(', ')
      : val.toString();
  }

  return typeof val === 'object' ? val.title || val.toString() : val.toString();
}

const ReportPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: report } = useSWR<Report>(`/reports/${id}`, fetcher);
  const [answer, changeAnswer] = useState('');
  const [status, changeStatus] = useState<
    'open' | 'closed' | 'rejected' | 'processed'
  >('open');

  useEffect(() => {
    if (report) {
      changeStatus(report.status);
    }
  }, [report]);

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      api
        .patch(`/reports/${report?.id}`, {
          ...report,
          answer: answer || undefined,
          status,
        })
        .then(() => window.location.reload());
    },
    [answer, report, status],
  );

  function handleClickDecline() {
    api
      .patch(`/reports/${report?.id}`, {
        ...report,
        status: ReportStatus.rejected,
      })
      .then(() => window.location.reload());
  }

  function handleClickApprove() {
    api
      .post('/reviews', {
        text: report?.payload?.text,
        ref: /\/([^/]+?)(?:#reviews)?$/.exec(report?.ref || '')?.[1],
        rating: report?.payload?.rating,
        displayName: `${report?.payload?.name}${
          report?.payload?.car ? ` на ${report?.payload?.car}` : ''
        }`,
        metadata: { date: report?.payload?.date },
      })
      .then(() =>
        api.patch(`/reports/${report?.id}`, {
          ...report,
          status: ReportStatus.closed,
        }),
      )
      .then(() => window.location.reload());
  }

  if (!report) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-2">
      <table className="table-auto">
        <tbody>
          <tr>
            <th>Date</th>
            <td>{new Date(report.createdAt).toLocaleString()}</td>
          </tr>
          <tr>
            <th>Subject</th>
            <td>{report.subject}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{report.status}</td>
          </tr>
          <tr>
            <th>Name</th>
            <td>{report.name}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{report.email}</td>
          </tr>
          {report.ref && (
            <tr>
              <th>Ref</th>
              <td>
                {report.ref}{' '}
                <a
                  className="text-blue-500 text-underline"
                  href={report.ref}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  open
                </a>
              </td>
            </tr>
          )}
          {report.text && (
            <tr>
              <th className="align-top">Text</th>
              <td className="whitespace-pre">{report.text}</td>
            </tr>
          )}
          {report.payload &&
            Object.entries(report.payload)
              .filter(([, val]) => !!val)
              .map(([key, val]) => (
                <tr key={key} className="border-t">
                  <th className="text-left font-normal text-gray-600 align-top py-2 pr-2">
                    {key}
                  </th>
                  <td className="whitespace-pre-line py-2 leading-tight break-all">
                    {payloadPropToString(val)}
                  </td>
                </tr>
              ))}
        </tbody>
      </table>

      {report.messages?.map((message: any) => (
        <div className="mb-2 pb-2 border-b" key={message.date}>
          <div className="flex gap-1 text-sm text-gray-600">
            <div>
              {message.from}, {message.date}
            </div>
          </div>
          <p className="whitespace-pre-line">{message.text}</p>
        </div>
      ))}

      {report.status !== ReportStatus.closed &&
      report.status !== ReportStatus.rejected &&
      report.subject === ReportSubject.review ? (
        <div className="space-x-2">
          <button
            onClick={handleClickApprove}
            className="h-8 bg-green-500 text-white px-4 rounded shadow"
          >
            Approve
          </button>
          <button
            onClick={handleClickDecline}
            className="h-8 bg-red-500 text-white px-4 rounded shadow"
          >
            Decline
          </button>
        </div>
      ) : (
        <form
          action=""
          className="mt-2 p-2 bg-gray-100 rounded"
          onSubmit={handleSubmit}
        >
          <div>
            <select
              className="bg-white border shadow h-8 mb-2 px-2 rounded"
              value={status}
              onChange={(event) => changeStatus(event.target.value as any)}
            >
              <option value="open">Open</option>
              <option value="closed">closed</option>
              <option value="processed">in process</option>
              <option value="rejected">rejected</option>
            </select>
          </div>
          <textarea
            className="p-2 rounded border shadow"
            placeholder="Answer..."
            value={answer}
            onChange={(event) => changeAnswer(event.target.value)}
          />
          <div className="mt-2">
            <button
              type="submit"
              className="h-8 bg-blue-500 text-white px-4 rounded shadow"
            >
              Send
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ReportPage;
