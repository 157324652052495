import React, { FC, FormEvent } from 'react';
import { useEditableServiceStation } from '../../hooks/use-editable-service-station';
import { PopulatedServiceStation } from '../../types/service-station.type';
import { normalizePhone } from '../../utils/normalize-pone';
import OptionsSelector from '../common/OptionsSelector';

interface Props {
  serviceStation?: PopulatedServiceStation;
  onSubmit: any;
  disabled: boolean;
}

const EditForm: FC<Props> = ({ onSubmit, serviceStation, disabled }) => {
  const { state, change, changeText } = useEditableServiceStation(
    serviceStation,
  );

  function handleSubmit(event: FormEvent): void {
    event.preventDefault();

    const coordinates = state.location
      ?.split(',')
      .map((item) => Number(item.trim()))
      .reverse();

    const data = {
      texts: state.texts,
      slug: state.slug,
      isDeleted: state.isDeleted,
      media: [],
      location:
        coordinates?.length === 2 ? { type: 'Point', coordinates } : undefined,
      phones: state.phones
        ?.split('\n')
        .map((item) => item.trim())
        .filter(Boolean)
        .map(normalizePhone),
      options: [
        state.city,
        ...(state.brands || []),
        ...(state.services || []),
      ].filter(Boolean),
    };

    onSubmit(data);
  }

  return (
    <form action="" className="max-w-2xl" onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 gap-2">
        <input
          type="text"
          placeholder="Name"
          value={state.texts?.name || ''}
          onChange={(event) => changeText('name', event.target.value)}
          className="w-full h-10 px-2 border rounded mb-2"
        />
        <input
          type="text"
          placeholder="Name UK"
          value={state.texts?.nameUk || ''}
          onChange={(event) => changeText('nameUk', event.target.value)}
          className="w-full h-10 px-2 border rounded"
        />
      </div>
      <div className="grid grid-cols-2 gap-2">
        <input
          type="text"
          placeholder="Address"
          value={state.texts?.address || ''}
          onChange={(event) => changeText('address', event.target.value)}
          className="w-full h-10 px-2 border rounded mb-2"
        />
        <input
          type="text"
          placeholder="Address UK"
          value={state.texts?.addressUk || ''}
          onChange={(event) => changeText('addressUk', event.target.value)}
          className="w-full h-10 px-2 border rounded"
        />
      </div>
      <div className="grid grid-cols-2 gap-2">
        <textarea
          placeholder="Description"
          value={state.texts?.description || ''}
          onChange={(event) => changeText('description', event.target.value)}
          className="w-full h-48 px-2 border rounded mb-2"
        />
        <textarea
          placeholder="Description UK"
          value={state.texts?.descriptionUk || ''}
          onChange={(event) => changeText('descriptionUk', event.target.value)}
          className="w-full h-48 px-2 border rounded"
        />
      </div>
      <input
        type="text"
        placeholder="Slug"
        value={state.slug || ''}
        onChange={(event) => change({ slug: event.target.value.trim() })}
        className="w-full h-10 px-2 border rounded mb-2"
      />
      <input
        type="text"
        placeholder="Location"
        value={state.location || ''}
        onChange={(event) => change({ location: event.target.value })}
        className="w-full h-10 px-2 border rounded mb-2"
      />
      <textarea
        placeholder="Phones"
        value={state.phones || ''}
        onChange={(event) => change({ phones: event.target.value })}
        className="w-full h-24 px-2 border rounded"
      />
      <OptionsSelector
        multiple={false}
        value={state.city}
        onChange={(value) => change({ city: value })}
        type="city"
        placeholder="City"
        className="mb-2"
      />
      <OptionsSelector
        multiple
        value={state.services}
        onChange={(value) => change({ services: value })}
        type="service"
        placeholder="Services"
        className="mb-2"
      />
      <OptionsSelector
        multiple
        value={state.brands}
        onChange={(value) => change({ brands: value })}
        type="brand"
        placeholder="Brands"
        className="mb-2"
      />
      <label className="block w-full mb-2 text-gray-500">
        Выключена{' '}
        <input
          checked={state.isDeleted || false}
          onChange={(event) => change({ isDeleted: event.target.checked })}
          type="checkbox"
        />
      </label>
      <button
        className="h-10 rounded bg-blue-500 text-white px-8 disabled:bg-gray-300"
        disabled={disabled}
      >
        Save
      </button>
    </form>
  );
};

export default EditForm;
