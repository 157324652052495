import React, { FC, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { escapeRegExp } from 'lodash';
import useDebounce from '../../../hooks/use-debounce';
import { Option } from '../../../types/option.type';

interface Props {
  multiple: boolean;
  type: string;
  value?: string[] | string;
  options?: Option[];
  onFinish: (selected: string[] | string) => void;
}

const SelectOptionsModal: FC<Modal.Props & Props> = ({
  onFinish,
  type,
  value,
  options,
  multiple,
  ...props
}) => {
  const [q, changeQ] = useState('');
  const debounceQ = useDebounce(q, 700);
  const [selected, changeSelected] = useState<string[]>(
    value ? (Array.isArray(value) ? value : [value]) : [],
  );

  useEffect(
    () => changeSelected(value ? (Array.isArray(value) ? value : [value]) : []),
    [value],
  );

  const filteredOptions = useMemo(() => {
    const queries = debounceQ
      .split(',')
      .map((v) => v.trim())
      .filter(Boolean);

    if (queries.length === 0) return options;

    const regex = new RegExp(
      queries.map((v) => escapeRegExp(v)).join('|'),
      'i',
    );

    return options?.filter(
      (option) =>
        regex.test(option.texts.name) ||
        regex.test(option.texts.nameRu || '') ||
        regex.test(option.texts.nameUk || ''),
    );
  }, [debounceQ, options]);

  function toggleOption(optionId: string, isSelected: boolean): void {
    const i = selected.indexOf(optionId);

    if (!multiple) {
      changeSelected([optionId]);
    } else if (isSelected && i === -1) {
      changeSelected([...selected, optionId]);
    } else if (!isSelected && i >= 0) {
      const newSelected = [...selected];
      newSelected.splice(i, 1);
      changeSelected(newSelected);
    }
  }

  return (
    <Modal {...props} bodyOpenClassName="overflow-hidden">
      {options ? (
        <div className="h-full flex flex-col">
          <div className="grid grid-cols-2 gap-2 overflow-hidden">
            <div className="h-full flex flex-col overflow-hidden">
              <input
                autoFocus
                className="outline-none"
                type="text"
                placeholder="search..."
                value={q}
                onChange={(event) => changeQ(event.target.value)}
              />
              <div className="overflow-y-auto">
                {filteredOptions?.map(
                  (option) =>
                    !selected.includes(option.id) && (
                      <div key={option.id}>
                        <label className="hover:text-gray-700">
                          <input
                            type="checkbox"
                            onChange={(event) =>
                              toggleOption(option.id, event.target.checked)
                            }
                          />{' '}
                          {option.texts.name}
                        </label>
                      </div>
                    ),
                )}
              </div>
            </div>
            <div className="overflow-y-auto">
              {options?.map(
                (option) =>
                  selected.includes(option.id) && (
                    <div key={option.id}>
                      <label className="hover:text-gray-700">
                        <input
                          checked
                          type="checkbox"
                          onChange={(event) =>
                            toggleOption(option.id, event.target.checked)
                          }
                        />{' '}
                        {option.texts.name}
                      </label>
                    </div>
                  ),
              )}
            </div>
          </div>
          <div className="flex gap-4 mt-2">
            {filteredOptions &&
              filteredOptions.length > 0 &&
              filteredOptions.length < 100 && (
                <button
                  onClick={() =>
                    changeSelected(filteredOptions.map((v) => v.id))
                  }
                  className="px-4 h-8 rounded bg-blue-500 text-white"
                >
                  Select all
                </button>
              )}
            {q && (
              <button
                onClick={() => changeQ('')}
                className="px-4 h-8 rounded bg-gray-300"
              >
                Reset filter
              </button>
            )}
            <button
              onClick={() => changeSelected([])}
              className="px-4 h-8 rounded bg-gray-300"
            >
              Reset selected
            </button>
            <button
              onClick={() => onFinish(multiple ? selected : selected[0])}
              className="px-4 h-8 rounded bg-blue-500 text-white"
            >
              OK
            </button>
          </div>
        </div>
      ) : (
        'loading...'
      )}
    </Modal>
  );
};

export default SelectOptionsModal;
