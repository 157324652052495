import { api } from './api';

let tokens = {
  access_token: '',
  refresh_token: '',
};

export const setTokens = (val: typeof tokens) => {
  tokens = val;
};

export const getTokens = () => tokens;

export const signIn = async (
  email: string,
  password: string,
): Promise<void> => {
  const { data } = await api.post('/auth/signin', { email, password });
  setTokens(data);
};

export const signUp = async (payload: {
  name: string;
  email: string;
  password: string;
}): Promise<void> => {
  await api.post('/auth/signup', payload);
};

export function isAuthenticated(): boolean {
  const { access_token: acessToken } = getTokens();
  if (acessToken) {
    const data = parseJwt(acessToken);
    return data.permissions?.includes('root');
  }
  return false;
}

function parseJwt(token: string): Record<string, any> {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}
