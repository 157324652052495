/* eslint-disable no-restricted-globals */
import React, { FC } from 'react';
import { api } from '../../../libs/api';
import { Option } from '../../../types/option.type';

interface Props {
  selected: Option[];
  onChange: (val: Option[]) => void;
  onMerge: () => void;
}

const SelectedOptionsPanel: FC<Props> = ({ selected, onChange, onMerge }) => {
  function handleMergeOptions(): void {
    const main = selected[selected.length - 1];
    if (
      !main ||
      !confirm(
        `Confrim merge "${main.slug}" option with ${
          selected.length - 1
        } others`,
      )
    ) {
      return;
    }

    api
      .post('/options/merge', {
        main: main.id,
        options: selected.slice(0, selected.length - 1).map((item) => item.id),
      })
      .then(() => {
        onChange([]);
        onMerge();
      })
      .catch((error) => alert(error.respone?.data || error));
  }

  return (
    <div className="space-x-2">
      <span className="text-gray-600">Selected: {selected.length}</span>
      <button onClick={() => onChange([])} className="rounded bg-gray-300 p-2">
        Cancel
      </button>
      {selected.length > 1 && (
        <button
          onClick={handleMergeOptions}
          className="rounded bg-blue-400 p-2"
        >
          Merge
        </button>
      )}
    </div>
  );
};

export default SelectedOptionsPanel;
