import React, { FC, useCallback, useMemo, MouseEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSWRInfinite } from 'swr';
import EditOptionModal from '../components/options/EditOptionModal';
import SelectedOptionsPanel from '../components/options/SelectedOptionsPanel';
import { useQuery } from '../hooks/use-query';
import { api, postFetcher } from '../libs/api';
import { Option } from '../types/option.type';

const LIMIT = 100;

const Options: FC = () => {
  const { push } = useHistory();
  const query = useQuery();
  const [editable, changeEditable] = useState<Option>();
  const [selectedOptions, changeSelectedOptions] = useState<Option[]>([]);

  const params = useMemo(
    () => ({
      type: query.get('type') || undefined,
      parent: query.get('parent') || undefined,
      limit: LIMIT,
    }),
    [query],
  );

  const handleChangeTypeFilter = useCallback(
    (val: string) => {
      push(`/options?type=${val}`);
    },
    [push],
  );

  const { data: options, size, setSize, mutate } = useSWRInfinite<Option[]>(
    (index) => ['/options/_search', params, index * LIMIT],
    postFetcher,
  );

  const handleClickShowMore = useCallback(
    (event: MouseEvent) => {
      (event.target as HTMLButtonElement).blur();
      setSize(size + 1);
    },
    [size, setSize],
  );

  const handleUpdate = useCallback(
    (option: Option) => {
      api.patch(`/options/${option.id}`, option).then(({ data }) => {
        changeEditable(undefined);
        mutate();
      });
    },
    [mutate],
  );

  const handleIncWeight = useCallback(
    (option: Option, incValue: number) => {
      const weight = (option.metadata?.weight || 0) + incValue;
      handleUpdate({
        ...option,
        metadata: {
          ...option.metadata,
          weight,
        },
      });
    },
    [handleUpdate],
  );

  function toggleSelectOption(option: Option, selected: boolean): void {
    const key = selectedOptions.indexOf(option);
    if (selected && key === -1) {
      changeSelectedOptions([...selectedOptions, option]);
    } else if (!selected && key >= 0) {
      const newVal = [...selectedOptions];
      newVal.splice(key, 1);
      changeSelectedOptions(newVal);
    }
  }

  return (
    <div className="p-2">
      {editable && (
        <EditOptionModal
          isOpen
          onUpdate={handleUpdate}
          option={editable}
          onRequestClose={() => changeEditable(undefined)}
        />
      )}

      <div className="mb-4 border-b py-2 flex justify-between sticky top-0 bg-white">
        <select
          value={query.get('type') || ''}
          className="rounded shadow border p-2 bg-white"
          onChange={(event) => {
            handleChangeTypeFilter(event.target.value);
          }}
        >
          <option value="">Type</option>
          <option value="brand">Brand</option>
          <option value="region">Region</option>
          <option value="city">City</option>
          <option value="option">Option</option>
          <option value="drive">Drive</option>
          <option value="gearbox">Gearbox</option>
          <option value="body">Body</option>
          <option value="fuel">Fuel</option>
          <option value="service">Service</option>
        </select>

        <SelectedOptionsPanel
          selected={selectedOptions}
          onChange={changeSelectedOptions}
          onMerge={() => mutate()}
        />
      </div>

      <table>
        <thead>
          <tr>
            <th />
            <th>Type</th>
            <th>Slug</th>
            <th>Texts</th>
            <th>weight / population</th>
            <th>Groups</th>
            <th>Disabled</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(options?.flat() as Option[])?.map((option) => (
            <tr
              key={option.id}
              className={
                option.disabled
                  ? 'bg-red-200'
                  : selectedOptions.includes(option)
                  ? 'bg-blue-200'
                  : ''
              }
            >
              <td className="border">
                <label className="p-2 block">
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option)}
                    onChange={(event) =>
                      toggleSelectOption(option, event.target.checked)
                    }
                  />
                </label>
              </td>
              <td className="border p-2">{option.type}</td>
              <td className="border p-2">{option.slug}</td>
              <td className="border p-2">
                {option.texts &&
                  Object.entries(option.texts).map(([key, val]) => (
                    <div key={key}>
                      {key}: {Array.isArray(val) ? val.join(', ') : val}
                    </div>
                  ))}
              </td>
              <td
                className="border p-2"
                onClick={(event) =>
                  handleIncWeight(option, event.ctrlKey ? -1 : 1)
                }
              >
                {option.metadata?.weight || option.metadata?.population}
              </td>
              <td className="border p-2">{option.groups}</td>
              <td className="border p-2">
                <button
                  className="text-sm text-blue-500 pointer"
                  onClick={() =>
                    handleUpdate({ ...option, disabled: !option.disabled })
                  }
                >
                  {option.disabled ? '❌' : '✔️'}
                </button>
              </td>
              <td className="border p-2 space-x-2">
                <button
                  className="text-sm text-blue-500 underline"
                  onClick={() => changeEditable(option)}
                >
                  Edit
                </button>
                {option.type === 'brand' && (
                  <Link
                    className="text-sm text-blue-500 underline"
                    to={`/options?parent=${option.id}`}
                  >
                    Decedents
                  </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        className="mt-4 h-8 rounded-sm bg-blue-500 shadow text-white px-4"
        onClick={handleClickShowMore}
      >
        Show More
      </button>
    </div>
  );
};

export default Options;
