import uniq from 'lodash/uniq';
import { Media } from '../types/media.type';
import { Option, OptionTypes } from '../types/option.type';
import {
  PopulatedServiceStation,
  ServiceStation,
} from '../types/service-station.type';

interface PopulatedResponse<T, K> {
  data: T;
  populated: {
    [key in keyof K]: {
      [k: string]: K[key];
    };
  };
}

export function normalizeServiceStationData({
  data,
  populated,
}: PopulatedResponse<
  ServiceStation,
  { options: Option; media: Media }
>): PopulatedServiceStation {
  const options = uniq(data.options).map((id) => populated.options[id]);
  const media = data.media.map((id) => populated.media[id]);
  const optionsByType = options.reduce((acc, item) => {
    if (item.type === OptionTypes.city) {
      acc[item.type] = item;
    } else {
      acc[item.type] = [...(acc[item.type] || []), item];
    }
    return acc;
  }, {} as any);

  return {
    ...data,
    _options: optionsByType as PopulatedServiceStation['_options'],
    _media: media,
  };
}

export function normalizeServiceStationsData({
  data,
  populated,
}: PopulatedResponse<
  ServiceStation[],
  { options: Option; media: Media }
>): PopulatedServiceStation[] {
  return data.map((item) =>
    normalizeServiceStationData({ populated, data: item }),
  );
}

export function normalizeCarData<
  T extends { options: string[]; media: string[] },
  R extends {
    _options: Record<string, Option | Option[] | undefined>;
    _media: Media[];
  } & T
>({
  data,
  populated,
}: PopulatedResponse<T, { options: Option; media: Media }>): R {
  const options = data.options.map((id) => populated.options[id]);
  const media = data.media.map((id) => populated.media[id]);
  const optionsByType = options.reduce((acc, item) => {
    if (item.type === OptionTypes.option) {
      acc[item.type] = [...(acc[item.type] || []), item];
    } else {
      acc[item.type] = item;
    }
    return acc;
  }, {} as Record<OptionTypes, any>);

  return {
    ...data,
    _options: optionsByType as any,
    _media: media,
  } as R;
}
