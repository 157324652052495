import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../libs/api';
import { signIn } from '../libs/auth';

const Login: FC = () => {
  async function handleSubmit(event: FormEvent): Promise<void> {
    event.preventDefault();
    changeStatus('loading');
    try {
      await signIn(login, password);
      goBack();
    } finally {
      changeStatus('error');
    }
  }

  const goBack = useCallback(() => {
    const { from } = (location.state as any) || { from: { pathname: '/' } };
    history.replace(from);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data } = useSWR('/auth/me', fetcher);
  const history = useHistory();
  const location = useLocation();
  const [status, changeStatus] = useState<'stale' | 'loading' | 'error'>(
    'stale',
  );
  const [login, changeLogin] = useState('');
  const [password, changePassword] = useState('');

  useEffect(() => {
    if (data) {
      goBack();
    }
  }, [data, goBack]);

  return (
    <div>
      <form
        action=""
        method="post"
        className="p-2 max-w-sm"
        onSubmit={handleSubmit}
      >
        <div className="p-2">
          <input
            type="email"
            placeholder="email"
            className="border w-full rounded-sm shadow-sm h-8 px-2"
            value={login}
            onChange={(event) => changeLogin(event.target.value)}
          />
        </div>
        <div className="p-2">
          <input
            className="border w-full rounded-sm shadow-sm h-8 px-2"
            placeholder="password"
            type="password"
            value={password}
            onChange={(event) => changePassword(event.target.value)}
          />
        </div>
        <div className="p-2 flex items-center justify-between">
          <button
            disabled={status === 'loading'}
            type="submit"
            className={`rounded-sm ${
              status === 'error' ? 'bg-red-500' : 'bg-blue-500'
            } h-8 px-6 shadow text-white hover:bg-blue-600 disabled:bg-gray-500`}
          >
            Sign In
          </button>

          <Link
            to="/signup"
            className="block h-8 px-2 text-blue-500 hover:underline"
          >
            Sign Up
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
