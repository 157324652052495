import React, { FC, useState } from 'react';
import EditForm from '../components/service-stations/EditForm';
import { api } from '../libs/api';

const AddServiceStationPage: FC = () => {
  const [status, changeStatus] = useState<'stale' | 'loading' | 'saved'>(
    'stale',
  );

  function handleSubmit(data: unknown): void {
    if (status !== 'stale') return;
    changeStatus('loading');

    api
      .post('/service-stations', data)
      .then(({ data }) => {
        changeStatus('saved');
        window.location.href = `https://carsua.net/sto/city/-/-/${data.id}`;
      })
      .catch((err) => {
        changeStatus('stale');
        alert('error');
        console.log(err);
      });
  }

  if (status === 'saved') {
    return <div className="p-2 text-2xl">saved successfully!</div>;
  }

  return (
    <div className="p-2">
      <EditForm disabled={status === 'loading'} onSubmit={handleSubmit} />
    </div>
  );
};

export default AddServiceStationPage;
