import { useEffect, useReducer } from 'react';
import { PopulatedServiceStation } from '../types/service-station.type';

interface State {
  id?: string;
  services?: string[];
  brands?: string[];
  phones?: string;
  city?: string;
  slug?: string;
  location?: string;
  isDeleted?: boolean;
  texts?: Partial<PopulatedServiceStation['texts']>;
  metadata?: PopulatedServiceStation['metadata'];
}

type Events = { type: 'change'; payload: Partial<State> };

function reducer(state: State, action: Events) {
  switch (action.type) {
    case 'change':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

function makeInitialState(
  serviceStation: PopulatedServiceStation | undefined,
): State {
  return {
    id: serviceStation?.id,
    isDeleted: serviceStation?.isDeleted,
    phones: serviceStation?.phones.join('\n'),
    city: serviceStation?._options.city?.id,
    slug: serviceStation?.slug,
    texts: serviceStation?.texts,
    metadata: serviceStation?.metadata,
    location: serviceStation?.location?.coordinates
      ? [...serviceStation?.location?.coordinates].reverse().join(', ')
      : undefined,
    services: serviceStation?._options.service?.map((option) => option.id),
    brands: serviceStation?._options.brand?.map((option) => option.id),
  };
}

export function useEditableServiceStation(
  serviceStation?: PopulatedServiceStation,
) {
  const [state, dispatch] = useReducer(
    reducer,
    makeInitialState(serviceStation),
  );

  const change = (data: Partial<State>) =>
    dispatch({ type: 'change', payload: data });

  const changeText = <K extends keyof PopulatedServiceStation['texts']>(
    key: K,
    val: PopulatedServiceStation['texts'][K],
  ) =>
    dispatch({
      type: 'change',
      payload: { ...state, texts: { ...state.texts, [key]: val } },
    });

  useEffect(() => {
    if (serviceStation && state.id !== serviceStation.id) {
      change(makeInitialState(serviceStation));
    }
  }, [serviceStation, state.id]);

  return { state, change, changeText };
}
